<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal" role="form" @submit.prevent="submitForm">
          <div class="row">
            <div class="col-12">
              <b-form-group id="type" label-cols-sm="2" label-cols-lg="2"
                :label="$t('form.productsPriceType.fields.typeField')" label-for="type"
                :invalid-feedback="titleValidationMessage" :state="!isTitleValid ? false : null">
                <b-form-input id="type" v-model="item.title" @input="validateTitleField"
                  :state="!isTitleValid ? false : null"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group id="description" label-cols-sm="2" label-cols-lg="2"
                :label="$t('form.productsPriceType.fields.descriptionField')" label-for="description"
                :invalid-feedback="descriptionValidationMessage" :state="!isDescriptionValid ? false : null">
                <b-form-input id="description" v-model="item.description" @input="validateDescriptionField"
                  :state="!isDescriptionValid ? false : null"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group id="position" label-cols-sm="2" label-cols-lg="2"
                :label="$t('form.productsPriceType.fields.positionField')" label-for="position"
                :invalid-feedback="positionValidationMessage" :state="!isPositionValid ? false : null">
                <b-form-input id="position" type="number" v-model="item.position" @input="validatePositionField"
                  :state="!isPositionValid ? false : null"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-4 mb-4 mt-4">
              <ModalSelectProductPriceType :fieldLabel="$t('form.productsPriceType.fields.variable1')"
                :emptyLabel="$t('form.productsPriceType.validation.variabla1Empty')" @selectItem="selectProductPriceTypeS1"
                @removeItem="removeProductPriceTypeS1" :discardItems="selectedProductPriceTypeS1List" :hidenItems="selectedProductPriceTypeS1ListHiden">
              </ModalSelectProductPriceType>
            </div>
            <div class="col-4 mb-4 mt-4">
              <ModalSelectProductOperatorType :fieldLabel="$t('form.productsPriceType.fields.operator')"
                :emptyLabel="$t('form.productsPriceType.validation.variabla2Empty')" @selectItem="selectOperatorTypeList"
                @removeItem="removeOperatorTypeList" :discardItems="selectedOperatorTypeList">
              </ModalSelectProductOperatorType>

            </div>
            <div class="col-4 mb-4 mt-4">
              <ModalSelectProductPriceType :fieldLabel="$t('form.productsPriceType.fields.variable2')"
                :emptyLabel="$t('form.productsPriceType.validation.variabla2Empty')" @selectItem="selectProductPriceTypeS2"
                @removeItem="removeProductPriceTypeS2" :discardItems="selectedProductPriceTypeS2List" :hidenItems="selectedProductPriceTypeS2ListHiden">
              </ModalSelectProductPriceType>
            </div>
            <div class="col-12">
              <b-button variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save')
                }}</b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSelectProductPriceType from "@/views/pages/product-price-type/modal-select-v1.vue"
import ModalSelectProductOperatorType from "@/views/pages/product-operator-type/modal-select.vue"
export default {
  components: {
    ModalSelectProductPriceType,
    ModalSelectProductOperatorType
  },
  data() {
    return {
      formValidationButton: true,
      isTitleValid: true,
      isDescriptionValid: true,
      isPositionValid: true,
      titleValidationMessage: null,
      descriptionValidationMessage: null,
      positionValidationMessage: null,
      selectedProductPriceTypeS1List: [],
      selectedProductPriceTypeS2List: [],
      selectedProductPriceTypeS1ListHiden:[],
      selectedProductPriceTypeS2ListHiden:[],
      selectedOperatorTypeList:[]
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: '',
        },
        description: {
          type: String,
          default: '',
        },
        position: {
          default: 1,
        },
        is_active: {
          type: Boolean,
          default: true,
        },
      }),
    },
    type: {
      type: String,
      default: ''
    }
  },
  watch: {
    'item'() {
      console.log(this.item.calculation)
      if (this.item.calculation){
        if(this.item.calculation.t1){
          this.selectedProductPriceTypeS1List.push(this.item.calculation.t1)
        }
        if(this.item.calculation.t2){
          this.selectedProductPriceTypeS2List.push(this.item.calculation.t2)
        }
        if(this.item.calculation.operator){
          this.selectedOperatorTypeList.push(this.item.calculation.operator)
        }

        

        if (this.item.id){
          this.selectedProductPriceTypeS1ListHiden = []
          this.selectedProductPriceTypeS2ListHiden = []
          this.selectedProductPriceTypeS1ListHiden.push(this.item)
          this.selectedProductPriceTypeS2ListHiden.push(this.item)
        }
      }
      this.validateForm();
    },
  },
  computed: {
    isFormValid() {
      return this.isTitleValid && this.isDescriptionValid && this.isPositionValid
    },
  },
  methods: {
    removeOperatorTypeList() {
      this.selectedOperatorTypeList = []
      this.validateForm();
    },
    selectOperatorTypeList(item) {
      this.selectedOperatorTypeList = []
      this.selectedOperatorTypeList.push(item)
      this.validateForm();
    },
    removeProductPriceTypeS1() {
      this.selectedProductPriceTypeS1List = []
      this.validateForm();
    },
    selectProductPriceTypeS1(item) {
      this.selectedProductPriceTypeS1List = []
      this.selectedProductPriceTypeS1List.push(item)
      this.validateForm();
    },
    removeProductPriceTypeS2() {
      this.selectedProductPriceTypeS2List = []
      this.validateForm();
    },
    selectProductPriceTypeS2(item) {
      this.selectedProductPriceTypeS2List = []
      this.selectedProductPriceTypeS2List.push(item)
      this.validateForm();
    },
    validateTitleField() {
      this.isTitleValid = this.item.title !== null && this.item.title.length > 0;
      this.titleValidationMessage = this.isTitleValid ? '' : this.$t('form.productsPriceType.validation.typeNameMandatory');
      this.validateForm()
    },
    validatePositionField() {
      this.isPositionValid = this.item.position !== null && this.item.position.length > 0;
      this.titlePositionMessage = this.isPositionValid ? '' : this.$t('form.productsPriceType.validation.typePositionMandatory');
      this.validateForm()
    },
    validateDescriptionField() {
      this.isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
      this.descriptionValidationMessage = this.isDescriptionValid ? '' : this.$t('form.productsPriceType.validation.typeDescriptionMandatory');
      this.validateForm()
    },
    validateForm() {
      var isTitleValid = this.item.title !== null && this.item.title.length > 0;
      var isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
      var isPositionValid = this.item.position !== null && parseInt(this.item.position) > 0;

      var selectedProductPriceTypeS1ListLenght = this.selectedProductPriceTypeS1List.length
      var selectedProductPriceTypeS2ListLenght = this.selectedProductPriceTypeS2List.length
      var selectedOperatorListLenght = this.selectedOperatorTypeList.length

      var calculationValid = false
      if (selectedProductPriceTypeS1ListLenght != 0 && selectedProductPriceTypeS2ListLenght != 0 && selectedOperatorListLenght != 0){
        calculationValid = true
      }
      if (selectedProductPriceTypeS1ListLenght == 0 && selectedProductPriceTypeS2ListLenght == 0 && selectedOperatorListLenght == 0){
        calculationValid = true
      }

      this.formValidationButton = !(isTitleValid == true && isDescriptionValid == true && isPositionValid == true && calculationValid == true);
    },
    submitForm() {
      if (this.isFormValid) {
        var s1 = null
        var s2 = null
        var operator = null
        if (this.selectedProductPriceTypeS1List.length != 0){
          s1 = this.selectedProductPriceTypeS1List[0].id
        }
        if (this.selectedProductPriceTypeS2List.length != 0){
          s2 = this.selectedProductPriceTypeS2List[0].id
        }
        if (this.selectedOperatorTypeList.length != 0){
          operator = this.selectedOperatorTypeList[0].id
        }


        const json = JSON.stringify({
          title: this.item.title,
          description: this.item.description,
          is_active: true,
          position: this.item.position,
          calculationT1:s1,
          calculationT2:s2,
          operator:operator
        });
        this.$emit('data', json);
      }
    },
  },
  mounted() {
    if (this.type == "CREATE") {
      this.isTitleValid = true
      this.isDescriptionValid = true
      this.titleValidationMessage = ""
      this.descriptionValidationMessage = ""
      this.formValidationButton = true
    }
  }
};
</script>